/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

let poolId, clientId, domainSuffixCognito;

// --- DEVELOPER laptops ---
if ( window.location.host == "localhost:8080" ) {             // Front-end Developer's Laptop
    poolId = 'us-east-1_Sx8vl4NET';                           // `dev` env
    clientId = '6h5kc0j5ar7rrba037vr0k82lp';                  // `dev` env
    // poolId = 'us-east-1_XcQH8l1FU';                        // `sarma` env
    // clientId = 'qnv09v4e4gmuiolnq0r92kimo';                // `sarma` env
    domainSuffixCognito = "dev"
} else if ( window.location.host == 'matt.dev.factrial.com' ) {                                   // DEVELOPER-specific-env on BIAD-Cloud
    poolId = 'us-east-1_xVWlybajI';               // `matt` -- Matt Mariano's own BACKEND env
    clientId = '5jvu149a8j45otipatus9d7sbq';      // `matt` -- Matt Mariano's own BACKEND env
    domainSuffixCognito = "dev"
} else if ( window.location.host == 'sarma.dev.factrial.com' ) {                                   // DEVELOPER-specific-env on BIAD-Cloud
    poolId = 'us-east-1_XcQH8l1FU';                           // `sarma` env
    clientId = 'qnv09v4e4gmuiolnq0r92kimo';                   // `sarma` env
    domainSuffixCognito = "dev"

// --- DEV ---
} else if ( window.location.host == 'www.dev.factrial.com' ) {                                   // DEV-env on BIAD-Cloud
    poolId = 'us-east-1_Sx8vl4NET';               // `dev` env
    clientId = '6h5kc0j5ar7rrba037vr0k82lp';      // `dev` env
    domainSuffixCognito = "dev"
} else if ( window.location.host == 'www.dev.emfact.net' || window.location.host == 'www.dev.em-fact.com' ) {     // Essex-Management AWS Account
    poolId = 'us-east-1_zYU7pM8sb';
    clientId = '4siona2f22e2b122j32gh5801v';
    domainSuffixCognito = "dev"
} else if ( window.location.host == 'www.dev.nci-fact.com' ) {                                   // DEV-env on CBIIT-CloudOne
    poolId = 'us-east-1_???????';
    clientId = '5????????????????????k3';
    domainSuffixCognito = "dev"

// --- INT ---
} else if ( window.location.host == 'www.int.factrial.com' ) {                                   // DEV-env on Essex BIAD-Cloud
    poolId = 'us-east-1_vRZDKxXxu';
    clientId = '5pfc353ghc55eoin5oqmlb69k3';
    domainSuffixCognito = "int"
} else if ( window.location.host == 'www.int.nci-fact.com' ) {                                    // INT-env on CBIIT-CloudOne
    poolId = 'us-east-1_???????';
    clientId = '5????????????????????k3';
    domainSuffixCognito = "int"

// --- UAT ---
} else if ( window.location.host == 'www.uat.factrial.com' ) {                                   // UAT-env on Essex BIAD-Cloud
    poolId = 'us-east-1_D7SAYsvXb';
    clientId = '7ej2de8d8o8khpb7eu6kmpl7j0';
    domainSuffixCognito = "uat"
} else if ( window.location.host == 'www.uat.emfact.net' || window.location.host == 'www.uat.em-fact.com' ) {     // Essex-Management AWS Account
    poolId = 'us-east-1_fvKf0U6q7';
    clientId = '5lpen2a2nm2g4ponu6rhi8b60c';
    domainSuffixCognito = "uat"
} else if ( window.location.host == 'www.uat.nci-fact.com' || window.location.host == 'www.stage.nci-fact.com' ) {    // UAT-env on CBIIT-CloudOne
    poolId = 'us-east-1_???????';
    clientId = '5????????????????????k3';
    domainSuffixCognito = "uat"

// --- PROD ---
} else if ( window.location.host == 'www.factrial.com' || window.location.host == 'www.prod.factrial.com' ) {                                       // PRODUCTION-env on Essex BIAD-Cloud
    poolId = 'us-east-1_EQfl74pOQ';
    clientId = '154q2v5impjp5hetgtrdds5339';
    domainSuffixCognito = "prod"
} else if ( window.location.host == 'www.nci-fact.com' ||  window.location.host == 'www.prod.nci-fact.com' ) {                                       // PRODUCTION-env on CBIIT-CloudOne
    poolId = 'us-east-1_???????';
    clientId = '5????????????????????k3';
    domainSuffixCognito = "prod"
}

const awsmobile = {
  aws_project_region: 'us-east-1',

  /* aws_cognito_identity_pool_id:
    'us-east-2:20b5a685-381f-4867-b247-879579db48a0', */

  aws_cognito_region: 'us-east-1',

  aws_user_pools_id: poolId,
  aws_user_pools_web_client_id: clientId,

  oauth: {
    name: 'Google',
    domain: `fact-${domainSuffixCognito}.auth.us-east-1.amazoncognito.com`, //may need to dynamically inject
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.origin, //no end "/" on aws, !important
    redirectSignOut: window.location.origin, //no end "/" on aws, !important
    responseType: 'code'
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

const awsConfig = {
  "Auth": {
    "region": "us-east-1",
    "identityPoolRegion": "us-east-1",
    "userPoolId": poolId,
    "userPoolWebClientId": clientId,
    "domain": `fact-${domainSuffixCognito}.auth.us-east-1.amazoncognito.com`,
    oauth: {
      name: 'Google',
      domain: `fact-${domainSuffixCognito}.auth.us-east-1.amazoncognito.com`, //may need to dynamically inject
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin, //no end "/" on aws, !important
      redirectSignOut: window.location.origin, //no end "/" on aws, !important
      responseType: 'code'
    },
  }
}

/* const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:20b5a685-381f-4867-b247-879579db48a0",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_f3hQHrCDE",
  "aws_user_pools_web_client_id": "j7el754q8gvpd8p68q8g5vd7v",
  oauth: {
    "domain": "oauthdemo1138748.auth.us-east-1.amazoncognito.com",
    "scope": ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    "redirectSignIn": "http://localhost:3000",
    "redirectSignOut": "http://localhost:3000",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
} */

export default awsConfig;
